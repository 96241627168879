import React, { memo } from 'react'
import Dialog from 'components/shared/Dialog'
import sx from './styles'

const VideoModal = ({
  src, title = 'YouTube video player', open, onClose, sx: sxProp = {}, autoplay = false,
  embed = true,
}) => {
  const embedFormatUrl = (url) => {
    const splitUrl = url.split('watch?v=')
    const embedUrl = `${splitUrl[0]}embed/${splitUrl[1]}${autoplay ? '?rel=0&autoplay=1' : null}`
    return embedUrl
  }
  if (!src) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{ ...sx.modal, ...sxProp }}
      disableEscapeKeyDown
    >
      <iframe
        sx={sx.iframe}
        height="100%"
        src={embed ? embedFormatUrl(src) : src}
        title={title}
        frameBorder="0"
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        loading="lazy"
        allowFullScreen
      />
    </Dialog>
  )
}

export default memo(VideoModal)
