import React, { memo } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import sx from './styles'

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
))

const DialogComponent = (props) => {
  const {
    sx: sxProp,
    children,
    onClose,
    fullScreen = false,
    showCloseButton = true,
    iconClosebutton = false,
    ...other
  } = props

  const isMobile = useMediaQuery((theme) => `(max-width:${theme.breakpoints.values.sm}px)`)

  return (
    <Dialog
      sx={{ ...sx.root, ...sxProp }}
      open
      TransitionComponent={Transition}
      onClose={onClose}
      fullScreen={isMobile || fullScreen}
      {...other}
    >
      {showCloseButton && (
        <>
          {isMobile || iconClosebutton ? (
            <IconButton
              sx={sx.dialogClose}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton
              sx={sx.dialogClose}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </>
      )}

      {children}
    </Dialog>
  )
}

export default memo(DialogComponent)
