const styles = {
  modal: {
    borderRadius: 4,
    '& .MuiDialog-paper': {
      width: 'calc(100vw - 60px)',
      height: 'calc(100vh - 60px)',
      backgroundColor: 'background.default',
    },
  },

  iframe: {
    '&iframe': {
      width: '100%',
      height: '100%',
    },
  },
}

export default styles
