const styles = {
  root: {
    backgroundColor: 'background.default',
  },
  container: {
    backgroundColor: 'background.default',
    borderTop: (theme) => `1px solid ${theme.palette.background.border}`,
    borderBottom: (theme) => `1px solid ${theme.palette.background.border}`,
    borderLeft: (theme) => `1px solid ${theme.palette.background.border}`,
    borderRight: (theme) => `1px solid ${theme.palette.background.border}`,
    borderRadius: 0.5,
  },
  containerHomeMobile: {
    borderRadius: 0.5,
    paddingY: 2,
  },
  containerVariant: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginY: 9,
  },
  containerVariantMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginY: 1,
    paddingY: 3,
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: { md: 417 },
    '&.withVideo': {
      cursor: 'pointer',
    },
    '& img': {
      width: '100%!important',
      maxHeight: 417,
      minHeight: '100% !important',
      minWidth: '100%!important',
      borderTopRightRadius: { md: 80 },
      borderBottomRightRadius: { xs: 80 },
      borderBottomLeftRadius: { xs: 80, md: 'unset' },
    },
  },
  timelineContainer: {
    paddingX: { xs: 2, md: 3 },
    paddingY: { xs: 3, md: 2 },
  },
  timelineContainerVariant: {
    // paddingY: 2,
    // pl: { xs: 0, md: 3 },
  },
  timeLineTitle: {
    '&.MuiTypography-h3': {
      color: 'text.primary',
    },
  },
  childContainer: {
    marginTop: { xs: 2, md: 0 },
    // borderTop: (theme) => `1px solid ${theme.palette.background.border}`,
  },
  iconPlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-20px',
    marginTop: '-20px',
    zIndex: 1000,
  },
  disclaimerContainer: {
    padding: 1,
    backgroundColor: 'background.grey450',
  },
}
export default styles
