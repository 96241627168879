const styles = {
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 2,
      paddingTop: { xs: 7, md: 10 },
      paddingBottom: { xs: 7, md: 4 },
      paddingX: { xs: 2, md: 4 },
      boxSizing: 'border-box',
      backgroundColor: '#ECF0F1',
    },
  },

  dialogClose: {
    color: '#828282',
    backgroundColor: '#fff',
    borderRadius: 32,
    textTransform: 'uppercase',
    position: 'absolute',
    zIndex: 999,
    right: (theme) => ({ xs: theme.spacing(), md: theme.spacing(2.5) }),
    top: (theme) => ({ xs: theme.spacing(), md: theme.spacing(1.5) }),
  },

  iframe: {
    zIndex: 1,
  },
}

export default styles
