/* eslint-disable camelcase */
import React, { memo, useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useMediaQuery from '@mui/material/useMediaQuery'
import PlayIcon from 'components/icons/play_circle_outline.svg'
import { addTestIdProp } from 'utils/universal'
import VideoModal from 'components/VTA/VideoModal'
import Link from 'next/link'
import usePageProps from 'hooks/usePageProps'
import { FormattedMessage, useIntl } from 'react-intl'
import sx from './styles'

const MainBanner = (props) => {
  const {
    sx: sxProp,
    title,
    subtitle,
    contactText,
    description,
    mainImage,
    video_url,
    testId: testIdProp,
    children,
    image_url,
    maxWidth = 'lg',
    alignItemsStart = false,
    variant = false,
  } = props

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'), {
    noSsr: true,
  })
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const showVideo = () => setIsVideoVisible((prev) => !prev)

  const { formatMessage: f } = useIntl()

  const { whatsapp } = usePageProps()
  const welcomeMessage = f({ id: 'SYC_WHATSAPP_CONTACT_MESSAGE' })

  const ImageContainer = ({ children: childrenImage }) => {
    if (image_url) {
      return (
        <Link
          href={image_url}
          passHref
        >
          <Typography component="a">{childrenImage}</Typography>
        </Link>
      )
    }
    return childrenImage
  }

  const mainImageRender = mainImage && (
    <Box
      sx={sx.mainContainer}
      onClick={video_url ? showVideo : null}
      className={clsx({
        pointer: video_url,
      })}
    >
      {video_url && (
        <>
          <Box sx={sx.iconPlay}>
            <PlayIcon />
          </Box>
        </>
      )}
      <ImageContainer>
        <Image
          src={mainImage?.url}
          blurDataURL={mainImage?.url}
          width={mainImage?.width}
          height={mainImage?.height}
          layout="responsive"
          alt={mainImage?.alt || mainImage?.title}
          priority
        />
      </ImageContainer>
    </Box>
  )

  const mobile = (
    <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
      >
        {title && (
          <Typography
            variant="h1"
            mb={2}
            sx={sx.mainTitle}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="h2"
            mb={2}
            color="primaryDark"
            className="MuiTypography-description"
          >
            {subtitle}
          </Typography>
        )}

        {(contactText) && (
          <Typography
            variant="body1"
            mb={3}
          >
            <FormattedMessage id={contactText} />
            <a
              style={{ color: '#343434' }}
              href={`//wa.me/${whatsapp}?text=${welcomeMessage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="SYC_SEND_US_A_MESSAGE" />
            </a>
          </Typography>
        )}

        {mainImageRender}

        {description && (
          <Typography
            mt={2}
            variant="body1"
          >
            {description}
          </Typography>
        )}

        <Box sx={sx.childContainer}>{children}</Box>
      </Grid>
    </>
  )

  const mobileVariant = (
    <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
      >

        {mainImageRender}

        {title && (
          <Typography
            variant="h1"
            my={2}
            sx={sx.mainTitle}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="h2"
            mb={2}
            color="primaryDark"
            className="MuiTypography-description"
          >
            {subtitle}
          </Typography>
        )}

        {(contactText) && (
          <Typography
            variant="body1"
            mb={3}
          >
            <FormattedMessage id={contactText} />
            <a
              style={{ color: '#343434' }}
              href={`//wa.me/${whatsapp}?text=${welcomeMessage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="SYC_SEND_US_A_MESSAGE" />
            </a>
          </Typography>
        )}

        {description && (
          <Typography
            mt={2}
            variant="body1"
          >
            {description}
          </Typography>
        )}

        <Box sx={sx.childContainer}>{children}</Box>
      </Grid>
    </>
  )

  const desktop = (
    <Grid
      container
      sx={sx.mainContainerBanner}
      className={clsx({
        alignItemsStart: alignItemsStart === true,
      })}
    >
      <Grid
        item
        xs={12}
        md={5}
        lg={5}
      >
        {title && (
          <Typography
            variant="h1"
            mb={2}
            sx={sx.mainTitle}
          >
            {title}
          </Typography>
        )}

        {subtitle && (
          <Typography
            variant="h2"
            mb={2}
            color="primaryDark"
            className="MuiTypography-description"
          >
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body1"
            mb={2}
          >
            {description}
          </Typography>
        )}

        {(contactText) && (
          <Typography
            variant="body1"
          >
            <FormattedMessage id={contactText} />
            <a
              style={{ color: '#343434' }}
              href={`//wa.me/${whatsapp}?text=${welcomeMessage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="SYC_SEND_US_A_MESSAGE" />
            </a>
          </Typography>
        )}

        <Box sx={sx.childContainer}>{children}</Box>
      </Grid>
      {mainImage && (
        <Grid
          item
          xs={12}
          md={6}
          sx={sx.mainContainer}
        >
          {mainImageRender}
        </Grid>
      )}
    </Grid>
  )

  return (
    <Container
      maxWidth={maxWidth}
      sx={{ ...sx.root, ...sxProp }}
      {...addTestIdProp(testIdProp)}
      className="Main-Banner-Container-root"
    >
      <Grid
        pt={7}
        container
        justifyContent="space-between"
      >
        {isMobile ? (variant ? mobileVariant : mobile) : desktop}
        <VideoModal
          src={video_url}
          open={isVideoVisible}
          onClose={showVideo}
          autoplay
        />
      </Grid>
    </Container>
  )
}

export default memo(MainBanner)
