import { useQuery } from 'react-query'
import axios from 'axios'

const useReviews = () => {
  const reviewsQuery = useQuery(
    ['reviews'],
    () => axios.get(`${process.env.NEXT_PUBLIC_API_ROOT}/api/my_business_reviews`),
    {
      staleTime: 1000 * 60 * 24,
    },
  )

  return reviewsQuery.data?.data?.result
}

export default useReviews
